@import '~react-datepicker/dist/react-datepicker.css';

body {
  background-color: #fff;
}

.form-group {
  padding-top: 10px;
  margin-bottom: 10px;
}

.form-signin label {
  display: none !important;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type='userEmail'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type='userPassword'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
  background-color: #003b75 !important;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1018px !important; /* or 950px */
}

.breadcrumb {
  background-color: #cc0000 !important;
  border-radius: 0 !important;
  height: 45px;
  align-content: center;
  padding-left: 15px !important;
}

a.breadcrumb-item {
  color: #ffffff !important;
}

.breadcrumb-item.active {
  color: #ffffff !important;
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff !important;
}

.home-card-deck {
  padding-top: 40px;
  display:flex;
}

.home-card {
  border-color: #17a2b8 !important;
  max-width: 400px;
  width: 400px;
  margin: 10px;
}

.home-card:first-child {
  margin-left: 0px;
}

.home-card:last-child {
  margin-right: 0px;
}

.card-admin {
  border-color: #6c757d !important;
}

.home-card .card-header {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #fff !important;
  text-align: center !important;
}

.home-card.card-admin .card-header {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.card-header-review {
  padding: 0rem 0.5rem 0rem 0.5rem !important;
}

.react-datepicker-wrapper {
  display: block;
}
.jumbotron {
  margin-bottom: 1rem !important;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 3rem 0rem 1rem 0rem !important;
  }
}

.vehicle-type {
  display: inline-block;
  margin-right: 10px;
  padding: 3px 6px 3px 6px;
  color: #155724;
  border: 0.5px solid #155724;
  background-color: #eaffef;
}

.expert-rating-scorecard tr {
  border: 12px solid white;
}

.scorecard-rating {
  display: inline-block;
  margin: 0 10px 0 10px;
  padding: 4px 8px 4px 8px;
  color: #000000;
  border: 0.5px solid #cc0000;
  background-color: #ffffff;
}

.model-entry-note {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px 6px 3px 6px;
  color: #000000;
  border: 0.5px solid #000000;
  background-color: #f5ffcb;
  display: block;
  width: 100%;
}

.model-entry-note-blank {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3px 6px 3px 6px;
  color: #444444;
  border: 0.5px solid #000000;
  background-color: #ffffff;
  display: block;
  width: 100%;
}

/**
Manage WP Plugin classes
**/

.manage-post-container {
  list-style: none;
  margin: 20px;
  padding: 0px;
}

.manage-post-block {
  border: 0.5px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
